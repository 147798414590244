<template>
  <div class="dropdown-list">
    <div class="dropdown-list-btn" @click="toggleMenu" ref="btn">
      <span class="display-text">{{ currentValue }}</span>
      <SvgIcon icon="arrow-down" />
    </div>
    <ul class="dropdown-list-menu" v-show="isOpen">
      <li
        class="list-item"
        v-for="(item, index) in items"
        :key="index"
        :class="{ 'is-selected': item === currentValue }"
        @click="updateCurrentValue(item)"
      >
        <SvgIcon icon="dropdown-selected" class="selected-icon" />
        <span>{{ item }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: String,
      required: true
    }
  },
  emits: ['change'],
  setup(props, ctx) {
    // 按鈕
    const btn = ref(null)
    // 開合狀態
    const isOpen = ref(false)
    const toggleMenu = () => (isOpen.value = !isOpen.value)

    // 項目選取處理
    const currentValue = ref(props.defaultValue)
    const updateCurrentValue = item => {
      currentValue.value = item
      // 發射 change custom event
      ctx.emit('change', item)
    }

    const onClick = e => {
      // @ts-ignore
      if (!btn.value.contains(e.target)) {
        // 點擊按鈕以外區域，將關閉 dropdown
        isOpen.value = false
      }
    }

    onMounted(() => {
      window.addEventListener('click', onClick)
    })

    onUnmounted(() => {
      window.removeEventListener('click', onClick)
    })

    return {
      btn,
      isOpen,
      toggleMenu,
      currentValue,
      updateCurrentValue
    }
  }
})
</script>

<style scoped></style>
