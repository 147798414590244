<template>
  <div
    class="top-events-card"
    :class="{ 'high-light': event.importance === 'high' }"
  >
    <router-link class="card-link" :to="{ name: pathName }"></router-link>
    <h4 class="card-count">{{ event.count }}</h4>
    <p class="card-title">{{ event.title }}</p>
    <div class="card-arrow">
      <SvgIcon icon="arrow-right" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const pathName = computed(() => {
      if (props.event.href !== '') {
        return props.event.href
      } else {
        return 'Home'
      }
    })
    return { pathName }
  }
})
</script>

<style scoped></style>
