
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const pathName = computed(() => {
      if (props.event.href !== '') {
        return props.event.href
      } else {
        return 'Home'
      }
    })
    return { pathName }
  }
})
